import { createAction } from 'redux-actions';
import { createPromiseAction } from 'core/modules/utils';

/**
 * Action Types
 */
export const CREATE_CHAT_CONTACT_REQUEST = '@chat/CREATE_CHAT_CONTACT_REQUEST';
export const GET_CHAT_CONTACT_REQUEST = '@chat/GET_CHAT_CONTACT_REQUEST';
/**
 * Action Creators
 */
export const chatActionCreators = {
  createChatContact: createPromiseAction(CREATE_CHAT_CONTACT_REQUEST),
  getChatContact: createPromiseAction(GET_CHAT_CONTACT_REQUEST)
};
