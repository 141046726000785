/* eslint-disable no-unused-vars */
import { put, call, fork, all, take } from 'redux-saga/effects';

import {
  HANDLE_AUTH_ERROR_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  GET_CODE_REQUEST,
  GET_EVENTS_REQUEST,
  authActionCreators
} from 'core/modules/auth/actions';

import { restService } from 'utilities';

export function* handleAuthErrorRequest({ payload, resolve, reject }) {
  const { response } = payload;
  try {
    if (response.data && response.status === 401) {
      yield put(authActionCreators.logOut({}));
    }
  } catch (e) {
    console.log('e', e);
  }
}

export function* asyncLoginRequest({ payload, resolve, reject }) {
  const { email, code } = payload;

  try {
    const response = yield call(restService, {
      api: `https://dev.crmcloudapi.com/v3/api/contact/otp_validate`,
      method: 'POST',
      params: {
        email,
        code
      },
      third_party: true
    });
    if (response.status === 200) {
      yield put(authActionCreators.loginSuccess({ user: { authorized: true, email } }));
      resolve(response.data);
    } else {
      yield put(authActionCreators.loginFailure({ user: { authorized: false, email: '' } }));
      reject(response);
    }
  } catch (e) {
    yield put(authActionCreators.loginFailure({ user: { authorized: false, email: '' } }));
    reject(e);
  }
}

export function* asyncLogoutRequest({ payload, resolve, reject }) {
  try {
    // const response = yield call(restService, {
    //   api: `/auth/logout`,
    //   method: 'PUT',
    //   params: {}
    // });

    // if (response.status === 200) {
    yield put(authActionCreators.logOutSuccess());
    // }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetCodeRequest({ payload, resolve, reject }) {
  const { email, otp_count } = payload;

  try {
    const response = yield call(restService, {
      api: `https://dev.crmcloudapi.com/v3/api/contact/codes`,
      method: 'POST',
      params: [
        {
          email: email.toLowerCase(),
          otp_count
        }
      ],
      third_party: true
    });
    if (response.status === 200) {
      resolve(response.data);
    } else {
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetEventsRequest({ payload, resolve, reject }) {
  try {
    const response = yield call(restService, {
      api: `https://dev.crmcloudapi.com/v3/api/events/e8233bea-c549-49b3-970b-f6ce9433862f/life_cycle`,
      method: 'GET',
      params: {},
      third_party: true
    });
    if (response.status === 200) {
      resolve(response.data);
    } else {
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* watchHandleAuthErrorRequest() {
  while (true) {
    const action = yield take(HANDLE_AUTH_ERROR_REQUEST);
    yield* handleAuthErrorRequest(action);
  }
}
export function* watchLoginRequest() {
  while (true) {
    const action = yield take(LOGIN_REQUEST);
    yield* asyncLoginRequest(action);
  }
}
export function* watchLogoutRequest() {
  while (true) {
    const action = yield take(LOGOUT_REQUEST);
    yield* asyncLogoutRequest(action);
  }
}
export function* watchGetCodeRequest() {
  while (true) {
    const action = yield take(GET_CODE_REQUEST);
    yield* asyncGetCodeRequest(action);
  }
}
export function* watchGetEventsRequest() {
  while (true) {
    const action = yield take(GET_EVENTS_REQUEST);
    yield* asyncGetEventsRequest(action);
  }
}

export default function*() {
  yield all([
    fork(watchHandleAuthErrorRequest),
    fork(watchLoginRequest),
    fork(watchLogoutRequest),
    fork(watchGetCodeRequest),
    fork(watchGetEventsRequest)
  ]);
}
