import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Popover, Input } from 'antd';
import VideoPlayer from 'react-video-js-player';
import LiveLike from '@livelike/engagementsdk';
import { connectAuth, connectChat, chatActionCreators } from 'core';
import MainLayout from 'containers/Layout/MainLayout';
import toast from 'components/Basic/Toast';
import { ReactComponent as Line1Img } from 'assets/img/line1.svg';
import { ReactComponent as Line2Img } from 'assets/img/line2.svg';
import { promisify } from 'utilities';

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 665px) {
    flex-direction: column;
  }
`;

const VideoSection = styled.div`
  flex: 2;
  background-color: #20272D;
  @media (max-width: 665px) {
    flex: none;
    width: 100%;
  }
  .video-js {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    @media (max-width: 665px) {
      min-height: unset;
      min-height: 212px;
    }
  }
`;

const AutionWrapper = styled.div`
  width: 100%;
  padding: 15px 20px 15px 33px;
  background: #070D11;

  @media (max-width: 665px) {
    flex-direction: row !important;
  }

  button {
    width: 238px;
    max-width: 238px;
    height: 48px;
    border-radius: 6px;
    border: none;
    padding-left: 19px;
    padding-right: 24.5px;
    @media (max-width: 665px) {
      width: unset;
      flex: 1;
    }
    p {
      font-weight: normal;
      font-size: 19px;
      line-height: 20px;
      text-align: left;
      margin-top: 5px;
      @media (max-width: 665px) {
        font-size: 12px;
      }
    }
    svg {
      width: 100%;
    }
  }
  .charity-btn {
    background: #E72334;
    margin-bottom: 21px;
    @media (max-width: 665px) {
      margin-bottom: 0px;
      margin-right: 10px;
    }
    p {
      color: #FFFFFF;
    }
  }
  .donation-btn {
    background: #FFFFFF;
    @media (max-width: 665px) {
      margin-left: 10px;
    }
    p {
      color: #E72334;
    }
  }
`;

const ChatSection = styled.div`
  position: relative;
  max-width: 428px;
  flex: 1;
  @media (max-width: 665px) {
    flex: none;
    width: 100%;
    height: calc(100% - 212px);
    max-width: unset;
  }
  .overlay-bg-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 100;
  }
  livelike-widgets {
    background: #070D11;
  }
  livelike-chat {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    // min-width: 400px;
    width: 100%;
    height: calc(100% - 400px);
    background: #070D11;
    padding: 28px;

    @media (max-width: 665px) {
      min-width: unset;
      height: inherit;
    }

    livelike-message-list {
      flex: 1;
      @media (max-width: 665px) {
        margin-bottom: 50px;
      }
      .message-item-container {
        .message-wrapper {
          background-color: white;
          border-bottom-left-radius: 0px;
          padding: 5px 10px;
          .sender-name {
            font-size: 16px;
            line-height: normal;
            color: #E72334;
            margin-bottom: 0px;
          }
          .sender-message {
            font-size: 18px;
            line-height: normal;
            color: #20272D;
            padding: 0;
          }
        }
      }

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
        height: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #F6F6F6; 
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #525862;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #525862; 
      }
    }

    livelike-chat-composer {
      width: 100%;
      @media (max-width: 665px) {
        position: sticky;
        bottom: 15px;
      }
    }

    .livelike-chat-input {
      background: #20272D;
      border-radius: 30px;
      border: none;
      font-size: 18px;
      color: #FFFFFF;
      &::placeholder {
        font-size: 18px;
        color: #FFFFFF;
      }
    }
  }
`;

let widgetContainer = null;
let chatInterval = null;

function Main({ user, history, createChatContact, getChatContact }) {
  const [isNewNameModal, setIsNewNameModal] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [newName, setNewName] = useState('');
  const [isCoverChat, setIsCoverChat] = useState(true);

  // useEffect(() => {
  //   document.addEventListener('mouseup', e => {
  //     const container = document.getElementById('new-name-modal');
  //     if (!container.contains(e.target)) {
  //       setIsNewNameModal(false);
  //       setNewName('');
  //     }
  //   });
  // }, []);

  const handleResultAnimation = e => {
    const { result, element, widget } = e.detail;
    // Results can be 'unattempted', 'correct', or 'incorrect'
    if (result !== 'unattempted') {
      // Grab animation based on results
      const imgUrl =
        result === 'correct'
          ? 'correct_animation.gif'
          : 'incorrect_animation.gif';
      // Create HTML string for animation
      const elStr = `
      <div class="animation-container" style="position: absolute; z-index: 10; left: 50%; height: 100%; top: 0; transform: translate(-50%,0); z-index: 1000; max-width: 100%;">
        <img class="animation-image" style="height:100%; width: auto;" src=${imgUrl} alt="Result animation">
      </div>
    `;
      // Append it to the widget
      element.insertAdjacentHTML('beforeend', elStr);
    }
  };

  const handleWidgetDetach = e => {
    const { widget, element } = e.detail;
    if (widget.kind === 'cheer-meter') {
      let content = [];
      // The cheer meter has an `options` array. Each option contains the description that
      // was entered at the time of cheer meter creation, and the final vote count.
      // This example created a combined string with this information and creates an alert.
      // This information can be used to create any other UI you'd need.
      widget.options.forEach(option => {
        content.push({
          imgSrc: option.image_url,
          voteCount: option.vote_count
        });
      });
      toast.success({
        content
      });
    }
  };

  useEffect(() => {
    if (LiveLike) {
      LiveLike.init({ clientId: 'THl2sRDojFrwjes4fZa9pdktbTUS5iTxKOXu1TKd' }).then(profile => {
        setIsNewNameModal(true);
        // This will generate a new profile
        if (profile && profile.access_token) {
          setAccessToken(profile.access_token);
        }
        widgetContainer = document.querySelector('livelike-widgets');
        if (widgetContainer) {
          // Fires once Quiz and Prediction widget timer's elapse
          widgetContainer.addEventListener('results', handleResultAnimation);

          // Fires before the widget is removed from the DOM.
          widgetContainer.addEventListener(
            'beforewidgetdetached',
            handleWidgetDetach
          );
        }
      });
    }
  }, [LiveLike]);

  const getUserChatInfo = () => {
    promisify(getChatContact, {
      email: user.email
    })
      .then(res => {
        const index = (res || []).findIndex(r => r.contact_email === user.email);
        if (index !== -1 && res[index].chat_allowed) {
          setIsCoverChat(false);
        }
      })
      .catch(error => {});
  };

  const handleUpdateProfile = ()=> {
    if (LiveLike && accessToken && newName) {
      setIsNewNameModal(false);
      // Update User profile
      const options = { nickname: newName };
      LiveLike.updateUserProfile({
        accessToken,
        options
      }).then(newProfile => console.log(newProfile));
      promisify(createChatContact, {
        contact_email: user.email,
        nickname: newName,
        chat_allowed: true
      })
        .then(res => {
          getUserChatInfo();
          chatInterval = setInterval(() => {
            getUserChatInfo();
          }, 1000 * 60);
        })
        .catch(error => {
          getUserChatInfo();
          chatInterval = setInterval(() => {
            getUserChatInfo();
          }, 1000 * 60);
        });
    }
  };

  return (
    <MainLayout>
      <MainWrapper className="flex">
        <VideoSection className="flex flex-column align-center just-center">
          <VideoPlayer
            controls
            src="https://stream.saintsfoundation.tv/live1.m3u8"
            autoplay
          />
        </VideoSection>
        <ChatSection className="flex flex-column">
          {isCoverChat && <div className="overlay-bg-wrapper" />}
          <AutionWrapper className="flex align-center just-center flex-column">
            <Button
              className="charity-btn"
              onClick={() => {
                window.open('https://saintsauction.com/lite-ui/?controller=lots&category=All%20Lots', '_blank');
              }}
            >
              <p><span>CHARITY</span> <span className="bold">AUCTION</span></p>
              <Line1Img />
            </Button>
            <Button
              className="donation-btn"
              onClick={() => {
                window.open('https://saintsauction.com/lite-ui/?controller=pledges&action=showPledge&id=2', '_blank');
              }}
            >
              <p><span>MAKE</span> <span className="bold">DONATION</span></p>
              <Line2Img />
            </Button>
          </AutionWrapper>
          <livelike-widgets
            id="livelikeWidget"
            className="root widget-container"
            programid="e8233bea-c549-49b3-970b-f6ce9433862f"
          />

          <livelike-chat
            // timestamps
            className="chat-widget"
            messagemenus
            // showAvatar
            roomid="1995ab30-d9fb-4318-be82-e1b5d75ad173"
          />
          <Popover
            id="new-name-modal"
            className="new-name-modal"
            placement="topLeft"
            content={
              <div className="flex flex-column align-center">
                <p
                  style={{
                    fontSize: 20,
                    color: 'black',
                    fontWeight: 'bold'
                  }}
                >
                  Almost Done
                </p>
                <p
                  style={{
                    fontSize: 16,
                    color: 'black',
                    marginBottom: 10
                  }}
                >
                  just give us your nickname for the chat
                </p>
                <Input
                  value={newName}
                  placeholder="New User Name"
                  onChange={e => setNewName(e.target.value)}
                />
                <Button
                  style={{
                    width: '100%',
                    marginTop: 15,
                    backgroundColor: '#070D11',
                    color: 'white'
                  }}
                  onClick={handleUpdateProfile}
                >
                  Join
                </Button>
              </div>
            }
            visible={isNewNameModal}
          />
        </ChatSection>
      </MainWrapper>
    </MainLayout>
  );
}

Main.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  createChatContact: PropTypes.func.isRequired,
  getChatContact: PropTypes.func.isRequired
};

Main.defaultProps = {
  history: {},
  user: {}
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

const mapDispatchToProps = dispatch => {
  const { createChatContact, getChatContact } = chatActionCreators;
  return bindActionCreators(
    {
      createChatContact,
      getChatContact
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAuth(mapStateToProps, undefined),
  connectChat(undefined, mapDispatchToProps)
)(Main);
