import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose } from 'recompose';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form, Input, Button, Icon } from 'antd';
import ReactCodeInput from 'react-code-input';
import { connectAuth, authActionCreators } from 'core';
import CountTimeDown from 'components/Basic/CountTimeDown';
import { promisify } from 'utilities';
import sfcImg from 'assets/img/sfc.svg';
import togetherImg from 'assets/img/together.png';
import { times } from 'lodash';

const LandingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: auto;
  background: #070707;

  .count-down-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    padding-top: 17px;

    @media (max-width: 768px) {
      position: relative;
      transform: unset;
      left: 0;
      top: 0;
    }
  }

  .success_msg {
    padding-left: 26px;
  }

  .landing-content {
    .landing-bg {
      position: absolute;
      width: 60vw;
      top: 102px;
      right: 0px;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .footer {
    width: 100%;
    height: 40vh;
    position: absolute;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(7, 7, 7, 0.0001) 0%, #272725 67.12%);

    img {
      width: 85%;
    }
  }
`;

const SignInWrapper = styled.div`
  position: absolute;
  left: 15%;
  top: 110px;
  z-index: 10;

  @media (max-width: 768px) {
    position: relative;
    left: 0;
    top: 0;
  }

  .ticket-wrapper {
    margin-top: 40px;

    p {
      font-size: 20px;
      color: #FFFFFF;
    }
    .date {
      font-weight: bold;
    }
    .ticket-disable-btn {
      min-width: 300px;
      height: 60px;
      font-size: 18px;
      color: #FFFFFF;
      background: #676767;
      border-radius: 30px;
      margin-top: 40px;
      border: none;
    }

    .ticket-btn {
      min-width: 300px;
      height: 60px;
      font-size: 18px;
      color: #FFFFFF;
      background: #E72334;
      border-radius: 30px;
      margin-top: 40px;
      margin-bottom: 40px;
      border: none;
    }

    @media (max-width: 768px) {
      padding: 0 23px;
      .ticket-btn {
        min-width: unset;
        height: unset;
        font-size: 14px;
        padding: 10px 20px;
      }
    }
  }

  img {
    width: fit-content;
    min-height: 314px;
    height: 25vh;
    @media (max-width: 768px) {
      width: calc(100% - 52px);
      height: unset;
      min-height: unset;
      margin: 0 auto;
    }
  }

  .input-label {
    font-size: 18px;
    color: #FFFFFF;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .signin-form {
    padding-left: 26px;
    @media (max-width: 768px) {
      padding: 0 23px;
    }
    .title {
      font-weight: normal;
      font-size: 30px;
      color: #FFFFFF;

      @media (max-width: 768px) {
        font-size: 21px;
      }
    }

    .form {
      margin-top: 34px;
      .email-wrapper {
        flex: 1;
        margin-right: 24px;
        input {
          width: 344px;
          height: 60px;
          background: #000000;
          box-shadow: 0px 0px 13px #2C2827;
          font-size: 20px;
          color: #B2B2B2;
          margin-top: 14px;
          border-radius: 0;
          border: none;
          border-bottom: 1px solid #979797;

          @media (max-width: 768px) {
            width: 100%;
            height: 40px;
          }
        }
      }

      .send-code-btn {
        width: 191px;
        height: 60px;
        background: #E72334;
        border-radius: 30px;
        font-weight: normal;
        font-size: 18px;
        color: #FFFFFF;
        border: none;
        margin-top: 15px;
        @media (max-width: 768px) {
          width: 101px;
          height: 40px;
          font-size: 14px;
        }
      }
    }
  }

  .already-code {
    width: fit-content;
    margin-left: 26px;
    font-size: 20px;
    color: #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
  }

  .unique-code {
    padding-left: 26px;
    margin-top: 20px;
    @media (max-width: 768px) {
      padding-left: 23px;
      margin-top: 0px;
      margin-bottom: 20px;

      .react-code-input {
        input {
          width: 44px !important;
          height: 41px !important;
          font-size: 16px !important;
        }
      }
    }
    .input-content {
      margin-top: 14px;

      .react-code-input {
        input {
          box-shadow: 0px 0px 16px #2C2827;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      }
    }
  }

  .invalid_msg {
    padding-left: 26px;
    margin-bottom: 10px;
  }

  .get-new-code {
    width: fit-content;
    margin-left: 26px;
    margin-top: 28px;
    font-size: 16px;
    color: #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    margin-bottom: 40px;
  }
`;

function Landing({ form, history, login, getCode, getEvents }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [isLiveDay, setIsLiveDay] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [currentMode, setCurrentMode] = useState({});
  const { getFieldDecorator } = form;

  useEffect(() => {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    promisify(getEvents, {})
      .then(res => {
        if (res) {
          const modes = res.filter(r => timestamp > r.changed_time * 1000);
          if (modes.length !== 0) {
            setCurrentMode(modes[0]);
          }
        }
      })
      .catch(error => {
        
      });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        // const duration = moment.duration(moment('2021-03-04 20:00').diff(moment()));
        // const hours = duration.asHours();
        // if (!isLiveDay && hours <= 1) {
        //   setIsSubmit(true);
        // } else if (isLiveDay) {
        //   setIsSubmit(true);
        // }
        setIsSubmit(true);
        if (values.email.toLowerCase() === 'support@vizulink.com') {
          setIsSubmit(true);
          setIsLiveDay(true);
          setIsSuperAdmin(true);
        }
        setIsLoading(true);
        promisify(getCode, { ...values, otp_count: 1 })
          .then(res => {
            setIsLoading(false);
          })
          .catch(error => {
            setErrMsg('Something went wrong');
            setIsLoading(false);
          });
      }
    });
  };

  const handleOnLive = () => {
    setIsLiveDay(true);
  };

  const handleChangeCode = v => {
    if (v && v.length === 4) {
      history.push('/main');
      // promisify(login, { email: form.getFieldValue('email'), code: v })
      //   .then(res => {
      //     setIsLoading(false);
      //     history.push('/main');
      //   })
      //   .catch(error => {
      //     setErrMsg('Code is invalid!');
      //     setIsLoading(false);
      //   });
    }
  };

  return (
    <LandingWrapper className="scrollbar">
      <div className="count-down-wrapper">
        <CountTimeDown onLive={handleOnLive} />
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="landing-content">
          <SignInWrapper className="flex flex-column">
            <img src={togetherImg} alt="together" />
            {/* <div className="ticket-wrapper">
              <p className="label">EVENT DATE:</p>
              <p className="date">4TH MARCH 2021 - 20:00</p>
              <Button className="flex align-center just-center ticket-disable-btn">
                TICKETS AVAILABLE SOON
              </Button>
              <Button
                className="flex align-center just-center ticket-btn"
                onClick={() => {
                  setIsPurchased(true);
                  window.open('https://saintsauction.com/lite-ui/?controller=tickets', '_blank');
                }}
              >
                PURCHASE TICKET
              </Button>
            </div> */}
            <div className="signin-form">
              <span className="title">SIGN IN TO LIVE EVENT</span>
              <div className="flex align-center form">
                <div className="email-wrapper input-wrapper">
                  <label className="input-label">Email</label>
                  <div className="input-content">
                    <Form.Item name="email">
                      {getFieldDecorator('email', {
                        rules: [
                          // {
                          //   type: 'email',
                          //   message: 'The input is not valid E-mail!'
                          // }
                        ]
                      })(<Input placeholder="enter email address…" />)}
                    </Form.Item>
                  </div>
                </div>
                {/* <Button
                  className="flex align-center just-center send-code-btn"
                  disabled={isLoading}
                  htmlType="submit"
                >
                  {isLoading ? <Icon type="loading" /> : null} SEND CODE
                </Button> */}
              </div>
            </div>
            {isSubmit && !errMsg ? <p className="success_msg">Your code will be sent if you have purchased a ticket</p> : ''}
            {/* {isPurchased ? <p className="success_msg">Your Code will be sent</p> : ''} */}
            {(currentMode.life_cycle === 'LIVE' || isSuperAdmin) && (
              <p className="already-code pointer" onClick={() => setIsSubmit(true)}>
                Already got a code click here
              </p>
            )}
            {isSubmit && (currentMode.life_cycle === 'LIVE' || isSuperAdmin) && (
              <div className="unique-code input-wrapper">
                <label className='input-label'>Enter your unique code</label>
                <div className="input-content">
                  <ReactCodeInput
                    fields={4}
                    type="number"
                    pattern="\d*"
                    autoFocus={false}
                    inputMode="numeric"
                    onChange={handleChangeCode}
                    inputStyle={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '0px',
                      fontSize: '20px',
                      textAlign: 'center',
                      backgroundColor: '#000000',
                      border: 'none',
                      borderBottom: '1px solid #979797',
                      marginRight: 20,
                      color: '#B2B2B2'
                    }}
                  />
                </div>
              </div>
            )}
            {errMsg && <p className="invalid_msg">{errMsg}</p>}
            {/* {((isLiveDay && currentMode.life_cycle !== 'LIVE') || isSuperAdmin) && (
              <p className="get-new-code pointer" onClick={handleGetNewCode}>
                Get a new code
              </p>
            )} */}
          </SignInWrapper>
          <img className="landing-bg" src={sfcImg} alt="kenzi" />
        </div>
      </Form>
      {/* <div className="flex flex-column align-center just-end footer">
        <img src={stripesImg} alt="footer" />
      </div> */}
    </LandingWrapper>
  );
}

Landing.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  user: PropTypes.object,
  login: PropTypes.func.isRequired,
  getCode: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired
};

Landing.defaultProps = {
  history: {},
  user: {},
  form: {}
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

const mapDispatchToProps = dispatch => {
  const { login, getCode, getEvents } = authActionCreators;
  return bindActionCreators(
    {
      login,
      getCode,
      getEvents
    },
    dispatch
  );
};

export default compose(
  withRouter,
  Form.create({ name: 'login_form' }),
  connectAuth(mapStateToProps, mapDispatchToProps)
)(Landing);
