import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from 'containers/Main';

class RoutesContainer extends PureComponent {
  render() {
    return (
      <Switch>
        <Route exact path="/main" component={Main} />
      </Switch>
    );
  }
}

export default RoutesContainer;
