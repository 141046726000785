import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import enMessages from 'lang/en';
import { store } from 'core';
import 'antd/dist/antd.css';
import RoutesContainer from 'containers/RoutesContainer/RoutesContainer';
import Landing from 'containers/Auth/Landing';
import { toast, ToastContainer, Slide } from 'react-toastify';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import 'assets/styles/App.scss';
import HttpsRedirect from "../HttpsRedirect";
import 'react-toastify/dist/ReactToastify.min.css';

addLocaleData([...en]);
const initialLang = 'en';

const messages = {
  en: enMessages
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: initialLang
    };
  }

  render() {
    const { lang } = this.state;
    const message = messages[lang];
    return (
      <IntlProvider locale={lang} messages={message}>
        <Provider store={store}>
          <HttpsRedirect>
            <ToastContainer
              autoClose={1000 * 30}
              transition={Slide}
              hideProgressBar
              newestOnTop
              position={toast.POSITION.TOP_RIGHT}
            />
            <HashRouter>
              <Switch>
                <Route exact path="/" component={Landing} />
                <PrivateRoute path="/main" component={RoutesContainer} />
                {/* <Redirect from="/" to="/" /> */}
              </Switch>
            </HashRouter>
          </HttpsRedirect>
        </Provider>
      </IntlProvider>
    );
  }
}

export default hot(App);
