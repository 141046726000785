const auth = {
  user: null
};

const chat = {
};

export const initialState = {
  auth,
  chat
};
