import { connect } from 'react-redux';
import { chatActionCreators } from 'core/modules/chat/actions';

function mapStateToProps({ chat }) {
  return {
    chat
  };
}

const mapDispatchToProps = chatActionCreators;

export function connectChat(configMapStateToProps = mapStateToProps) {
  return connect(configMapStateToProps, mapDispatchToProps);
}
