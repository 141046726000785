import React from 'react';
import PropTypes from 'prop-types';
import { toast as toastify } from 'react-toastify';

export function Toast({ content, type, ...props }) {
  return (
    <div
      {...props}
      className={`flex flex-column aling-center toast_container_wrapper ${type}`}
    >
      <p
        className="toast-title center"
        style={{
          fontSize: 36
        }}
      >
        RESULTS ARE IN
      </p>
      <div className="flex align-center">
        {content.map(c => (
          <div className="flex align-center toast-item">
            <img
              width="150"
              height="150"
              style={{ marginRight: 30 }}
              src={c.imgSrc}
              alt="person"
            />
            <span style={{ fontSize: 40 }}>{c.voteCount || 0}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

Toast.propTypes = {
  content: PropTypes.array,
  type: PropTypes.oneOf(['success', 'info', 'error'])
};

Toast.defaultProps = {
  content: [],
  type: 'success'
};

function toast({ content, type = 'success', ...props }, options = {}) {
  return toastify(<Toast content={content} type={type} {...props} />, options);
}

toast.info = (content, options) => toast({ ...content, type: 'info' }, options);
toast.error = (content, options) => toast({ ...content, type: 'error' }, options);
toast.success = (content, options) => toast({ ...content, type: 'success' }, options);
toast.update = toastify.update;

export default toast;
