import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Countdown from 'react-countdown';
import liveImg from 'assets/img/live.png';

const CountDownWrapper = styled.div`
  .title {
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 200;

    img {
      margin-right: 10px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .box-wrapper {
    .box {
      > div {
        width: 60px;
        height: 60px;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 44.2973px;
        box-shadow: 0px 0px 13px #2C2827;

        @media (max-width: 768px) {
          width: 30px;
          height: 30px;
          font-size: 22px;
        }
      }
      span {
        font-size: 24.1622px;
        color: #FFFFFF;
        font-weight: 200;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    .comma {
      width: 60px;
      height: 60px;
      font-weight: bold;
      font-size: 44.2973px;
      color: #FFFFFF;
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
        font-size: 22px;
      }
    }
  }
`;

function CountTimeDown({ onLive }) {
  const [isLive, setIsLive] = useState(false);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsLive(true);
      onLive();
      return <></>;
      // Render a completed state
    }
    // Render a countdown
    return (
      <div className="flex just-center box-wrapper">
        <div className="flex flex-column align-center just-center box">
          <div className="flex align-center just-center">{days}</div>
          <span>DAYS</span>
        </div>
        <div className="comma flex flex-column align-center just-center">:</div>
        <div className="flex flex-column align-center just-center box">
          <div className="flex align-center just-center">{hours}</div>
          <span>HOURS</span>
        </div>
        <div className="comma flex flex-column align-center just-center">:</div>
        <div className="flex flex-column align-center just-center box">
          <div className="flex align-center just-center">{minutes}</div>
          <span>MINS</span>
        </div>
        <div className="comma flex flex-column align-center just-center">:</div>
        <div className="flex flex-column align-center just-center box">
          <div className="flex align-center just-center">{seconds}</div>
          <span>SEC</span>
        </div>
      </div>
    );
  };
  return (
    <CountDownWrapper>
      <p className="flex align-center just-center title">
        {isLive && <img src={liveImg} alt="live" />}
        {isLive ? 'EVENT LIVE' : 'EVENT LIVE IN'}
      </p>
      {!isLive && (
        <Countdown
          date={moment('2021-03-04 20:00').unix() * 1000}
          renderer={renderer}
        />
      )}
    </CountDownWrapper>
  );
}

CountTimeDown.propTypes = {
  onLive: PropTypes.func
};

CountTimeDown.defaultProps = {
  onLive: () => {}
};

export default CountTimeDown;
