/* eslint-disable no-unused-vars */
import { put, call, fork, all, take } from 'redux-saga/effects';

import {
  CREATE_CHAT_CONTACT_REQUEST,
  GET_CHAT_CONTACT_REQUEST,
  chatActionCreators
} from 'core/modules/chat/actions';

import { restService } from 'utilities';

export function* asyncCreateChatContactRequest({ payload, resolve, reject }) {
  const { contact_email, nickname, chat_allowed } = payload;

  try {
    const response = yield call(restService, {
      api: `https://dev.crmcloudapi.com/v3/api/contact/chats`,
      method: 'POST',
      params: [
        {
          contact_email,
          nickname,
          chat_allowed
        }
      ],
      third_party: true
    });
    if (response.status === 200) {
      resolve(response.data);
    } else {
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* asyncGetChatContactRequest({ payload, resolve, reject }) {
  const { email } = payload;

  try {
    const response = yield call(restService, {
      api: `https://dev.crmcloudapi.com/v3/api/contact/chats?email=${email}`,
      method: 'GET',
      params: {},
      third_party: true
    });
    if (response.status === 200) {
      resolve(response.data);
    } else {
      reject(response);
    }
  } catch (e) {
    reject(e);
  }
}

export function* watchCreateChatContactRequest() {
  while (true) {
    const action = yield take(CREATE_CHAT_CONTACT_REQUEST);
    yield* asyncCreateChatContactRequest(action);
  }
}
export function* watchGetChatContactRequest() {
  while (true) {
    const action = yield take(GET_CHAT_CONTACT_REQUEST);
    yield* asyncGetChatContactRequest(action);
  }
}

export default function*() {
  yield all([
    fork(watchCreateChatContactRequest),
    fork(watchGetChatContactRequest)
  ]);
}
