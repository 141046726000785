import React from 'react';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import logoImg from 'assets/img/logo-company.png';
import liveImg from 'assets/img/live.png';
import barrattImg from 'assets/img/barratt.svg';
import vizulinkImg from 'assets/img/vizulinkLogo.svg';

const HeaderWrapper = styled.div`
  width: 100%;
  height: 72px;
  background: #000000;
  padding 8px 95px 8px 80px;

  @media (max-width: 976px) {
    padding 12px 24px;
  }

  .title {
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 200;
    margin-right: 81px;

    img {
      margin-right: 10px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      margin-right: 0px;
    }
  }
  
  .barratt-logo {
    margin-right: 44px;
  }

  @media (max-width: 768px) {
    .vizulink-logo,
    .barratt-logo {
      display: none;
    }
  }
`;

function Header({ intl }) {
  return (
    <HeaderWrapper className="flex align-center just-between">
      <img src={logoImg} alt="logo" />
      <div className="flex align-center">
        <p className="flex align-center just-center title">
          <img src={liveImg} alt="live" />
          EVENT LIVE
        </p>
        <img className="barratt-logo" src={barrattImg} alt="barratt" />
        <img className="vizulink-logo" src={vizulinkImg} alt="vizulink" />
      </div>
    </HeaderWrapper>
  );
}

Header.propTypes = {
  intl: intlShape.isRequired
};

Header.defaultProps = {
};

export default compose(injectIntl)(Header);
